/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: '/dashboard',
    name: 'Dashboard',
    tagColor: 'warning',
    icon: 'HomeIcon',
    i18n: 'Dashboard'
  },
  {
    header: 'Learning',
    i18n: 'Learning',
    items: [
      {
        url: '/courses',
        name: 'Course Selector',
        icon: 'PlayIcon',
        i18n: 'Course Selector'
      }
      // {
      //   url: '/knowledge-base',
      //   slug: 'page-knowledge-base',
      //   name: 'Knowledge Base',
      //   icon: 'InfoIcon',
      //   i18n: 'KnowledgeBase'
      // },
    ]
  },

  {
    header: 'Management',
    i18n: 'Management',
    rule: 'proctor',
    items: [
      {
        url: '/a/users',
        name: 'Users',
        icon: 'UsersIcon',
        i18n: 'Users'
      }
    ]
  },
  {
    header: 'Accounting',
    icon: 'Dollar2Icon',
    i18n: 'Accounting',
    rule: 'accountant',
    items: [
      {
        url: '/a/subscriptions',
        name: 'Subscriptions',
        icon: 'LayersIcon',
        i18n: 'Subscriptions'
      }
    ]
  },
  {
    header: 'Proctor',
    icon: 'Dollar2Icon',
    i18n: 'Proctor',
    rule: 'proctor',
    items: [
      {
        url: '/a/courses',
        name: 'Courses',
        icon: 'ShoppingBagIcon',
        i18n: 'Courses'
      },
      {
        url: '/a/modules',
        name: 'Modules',
        icon: 'PackageIcon',
        i18n: 'Modules'
      },
      {
        url: '/a/lessons',
        name: 'Lessons',
        icon: 'BookOpenIcon',
        i18n: 'Lessons'
      },
      {
        url: '/a/videos',
        name: 'Videos',
        icon: 'FilmIcon',
        i18n: 'Videos'
      },
      {
        url: '/a/attachments',
        name: 'Attachments',
        icon: 'PaperclipIcon',
        i18n: 'Attachments'
      },
    ]
  },
  {
    header: 'My Portal',
    icon: 'UserIcon',
    i18n: 'My Portal',
    items: [
      {
        url: '/settings',
        name: 'User Settings',
        icon: 'SettingsIcon',
        i18n: 'UserSettings',
      },{
        url: '/portal',
        name: 'Exit to Portal',
        icon: 'ArrowLeftIcon',
        i18n: 'Exit to Portal',
      },
    ]
  }
]

