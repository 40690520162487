<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]" class="layout--main">

    <vx-tour v-if="!disableThemeTour && (windowWidth >= 1200 && mainLayoutType === 'vertical' && verticalNavMenuWidth == 'default')"
             :steps="steps"/>

    <v-nav-menu
      :navMenuItems="navMenuItems"
      parent=".layout--main"
      title="Seminars"/>

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay"/>

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"
          :navbarType="navbarType"/>

        <div v-if="navbarType === 'static'" style="height: 62px"></div>

        <h-nav-menu
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"
          :navMenuItems="navMenuItems"/>
      </template>

      <template v-else>
        <the-navbar-vertical
          :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"
          :navbarColor="navbarColor"/>
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <transition :name="routerTransition">

              <div v-if="($route.meta.breadcrumb || $route.meta.pageTitle) && !$route.meta.titleless"
                   class="router-header flex flex-wrap items-center mb-6">
                <div
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}"
                  class="content-area__heading">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb v-if="$route.meta.breadcrumb" :isRTL="$vs.rtl" :route="$route"
                               class="ml-4 md:block hidden"/>

              </div>
            </transition>

            <div class="content-area__content">

              <back-to-top v-if="!hideScrollToTop" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" bottom="5%"
                           visibleoffset="500">
                <vs-button class="shadow-lg btn-back-to-top" icon="icon-arrow-up" icon-pack="feather"/>
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle"
                             @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"/>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer/>
    </div>


  </div>
</template>


<script>
import BackToTop from 'vue-backtotop';
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue';
import navMenuItems from '@/layouts/components/vertical-nav-menu/navMenuItems.js';
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue';
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue';
import TheFooter from '@/layouts/components/TheFooter.vue';
import themeConfig from '@/../themeConfig.js';
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue';

const VxTour = () => import('@/components/VxTour.vue')

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/m1ku2ayc'
  (function() {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/m1ku2ayc';
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    VxTour,
  },
  data() {
    return {
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'floating',
      navMenuItems,
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$route.meta.pageTitle,
      steps: [
        {
          target: '#btnVNavMenuMinToggler',
          content: 'Toggle Collapse Sidebar.',
        },
        {
          target: '.vx-navbar__starred-pages',
          content: 'Create your own bookmarks. You can also re-arrange them using drag & drop.',
        },
        {
          target: '.i18n-locale',
          content: 'You can change language from here.',
        },
        {
          target: '.navbar-fuzzy-search',
          content: 'Try fuzzy search to visit pages in flash.',
        },
        {
          target: '.customizer-btn',
          content: 'Customize template based on your preference',
          params: {
            placement: 'left',
          },
        },
        {
          target: '.vs-button.buy-now',
          content: 'Buy this awesomeness at affordable price!',
          params: {
            placement: 'top',
          },
        },
      ],
    };
  },
  watch: {
    '$route'() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor === '#fff' && val ? '#1B1E22' : '#fff'
      this.updateNavbarColor(color);
    },
    '$store.state.mainLayoutType'(val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
  },
  computed: {
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth === 'default') return 'content-area-reduced';
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg';
        else return 'content-area-full';
      } else return 'content-area-full';
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static',
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() {
      return this.$store.state.theme === 'dark';
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating',
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      if (val === 'static') this.updateNavbarColor(this.isThemeDark ? '#10163a' : '#fff');
      this.navbarType = val;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val === '#fff') this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) ||
        (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false);
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu');
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
  },
  created() {
    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#1B1E22' : this.navbarColor
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch('$store.state.windowWidth', (val) => {
      if (val < 1200) {
        this.disableThemeTour = true;
        this.dynamicWatchers.windowWidth();
      }
    });

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch('$store.state.verticalNavMenuWidth', () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.verticalNavMenuWidth();
    });

    this.dynamicWatchers.rtl = this.$watch('$vs.rtl', () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });

    this.$http.post(`users/@me/livechat`)
      .then(response => {

        if (response.data) {
          const payload = response.data.data;

          window.Intercom("boot", {
            app_id: "m1ku2ayc",
            avatar: {
              type: 'avatar',
              image_url: this.activeUserInfo.photoURL
            },

            name: `${this.activeUserInfo.first_name} ${this.activeUserInfo.last_name}`,
            email: this.activeUserInfo.email,
            phone: this.activeUserInfo.phone_number,
            company: {
              id: this.activeUserInfo.company,
              name: this.activeUserInfo.company
            },
            gender: this.activeUserInfo.gender,
            dob: this.activeUserInfo.dob,
            country: this.activeUserInfo.country,

            user_id: this.activeUserInfo.id,
            user_hash: payload.hash
          });
        }

      })

  },
  beforeDestroy() {
    Object.keys(this.dynamicWatchers).map(i => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
  },
};

</script>

