<template>
  <div v-if="activeUserInfo.full_name" class="the-navbar__user-meta flex items-center">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.full_name }}</p>
      <small>Available</small>
    </div>

    <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>

      <div class="con-img ml-3">
        <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL"
             alt="user-img" class="rounded-lg shadow-md cursor-pointer block"
             height="40" width="40"/>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/settings').catch(() => {})">
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Settings</span>
          </li>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/discord').catch(() => {})">
            <feather-icon icon="MessageCircleIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Discord</span>
          </li>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="toggleTheme()">
            <feather-icon icon="ThermometerIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Toggle Theme</span>
          </li>

          <vs-divider class="m-1"/>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    toggleTheme() {

      if (localStorage.getItem('theme') === 'light') {
        localStorage.setItem('theme', 'dark');
      } else {
        localStorage.setItem('theme', 'light')
      }

      location.reload();
    },
    logout() {
      localStorage.removeItem('userInfo');

      // If JWT login
      if (localStorage.getItem('sessionToken')) {
        localStorage.removeItem('sessionToken');
      }

      location.href = `https://portal.rapid.education`;
    },
  },
};
</script>
