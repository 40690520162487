<template functional>
  <footer :class="classes" class="the-footer flex-wrap justify-between">
    <p>
      <!-- @formatter:off -->
      Copyright Rapid Education, LLC. © 2020. All Rights Reserved.
      <!--- Powered by <a href="https://intellectualpoint.com/?utm_source=rapidseminars&utm_medium=footer&utm_campaign=rapidseminars-passive" target="_blank">Intellectual Point</a>. --->
      <!-- @formatter:on -->
    </p>
    <p>
      <!-- @formatter:off -->
      <!-- @formatter:on -->
    </p>
  </footer>
</template>

<script>
export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String,
    },
  },
};
</script>
